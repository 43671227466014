import { createSlice } from "@reduxjs/toolkit";
// utils
import { axiosInstance as axios, endpoint } from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  active: [],
  index: 0,
};

const slice = createSlice({
  name: "semester",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Tahun Pelajaran
    getSemesterSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET Tahun Pelajaran AKTIF
    getSemesterAktifSuccess(state, action) {
      state.isLoading = false;
      state.active = action.payload;
    },

    // GET Tahun Pelajaran AKTIF
    saveSemesterSuccess(state, action) {
      state.isLoading = false;
      state.posts.unshift(action.payload);
    },

    // RESET STATE
    reset() {
      return initialState;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getSemesterSuccess, saveSemesterSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getSemester() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(endpoint.semester.root);
      let semesterActive = response.data.filter((val) => val.is_active == 1);
      dispatch(slice.actions.getSemesterSuccess(response.data));
      dispatch(slice.actions.getSemesterAktifSuccess(semesterActive));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSemesterAktif(jenjang_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = { active: 1 };
      const response = await axios.get(endpoint.semester.root, { params });
      dispatch(slice.actions.getSemesterAktifSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetSemester() {
  return async (dispatch) => {
    dispatch(slice.actions.reset);
  };
}
