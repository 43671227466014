import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import uiReducer from "./slices/ui";
import userReducer from "./slices/user";
import authReducer from "./slices/auth";
import settingsReducer from "./slices/settings";
import jenjangReducer from "./slices/jenjang";
import tahunAjaranReducer from "./slices/tahun_ajaran";
import modalsReducer from "./slices/modal";
import informationReducer from "./slices/information";
import semesterReducer from "./slices/semester";
import calenderReducer from "./slices/calendar";
import PerusahaanReducer from './slices/perusahaan';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: ["settings", "jenjang", "tahun_ajaran", "semester", "perusahaan"],
};

const authPersistConfig = {
  key: "auth",
  storage,
  keyPrefix: "redux-",
  whitelist: ["isAuthenticated"],
};

const rootReducer = combineReducers({
  user: userReducer,
  ui: uiReducer,
  settings: settingsReducer,
  modals: modalsReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  jenjang: jenjangReducer,
  tahun_ajaran: tahunAjaranReducer,
  information: informationReducer,
  calendar: calenderReducer,
  semester: semesterReducer,
  perusahaan:PerusahaanReducer,
});

export { rootPersistConfig, rootReducer };
