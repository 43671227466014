import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  modalJenjang: false,
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    switchModalJenjang(state, action) {
      state.modalJenjang = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { switchModalJenjang } = slice.actions;
