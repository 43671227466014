import Icon from "@material-ui/core/Icon";
import { useRef, useState } from "react";
// material
import { alpha } from "@material-ui/core/styles";
import { Avatar, Grid } from "@material-ui/core";
// hooks
import useAuth from "../../hooks/useAuth";
// components
import { MIconButton } from "../../components/@material-extend";
import MenuPopover from "../../components/MenuPopover";
import ApplicationsAvailable from "src/components/general/analytics/ApplicationsAvailable";

// ----------------------------------------------------------------------

export default function ApplicationPopover() {
  const anchorRef = useRef(null);
  const { applications = [] } = useAuth();
  const [open, setOpen] = useState(false);
  const [claerAll, setClaerAll] = useState(false);

  const handleOpen = () => {
    setClaerAll(true);
    setOpen(true);
  };
  const handleClose = () => {
    setClaerAll(true);
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar>
          <Icon>apps</Icon>
        </Avatar>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: "70%" }}
      >
        <Grid item xs={12}>
          <ApplicationsAvailable
            cleardata={claerAll}
            applications={applications}
          />
        </Grid>
      </MenuPopover>
    </>
  );
}
