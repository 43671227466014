import PropTypes from "prop-types";
import { useState } from "react";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";

import { useDispatch, useSelector } from "react-redux";
import { switchModalJenjang } from "src/redux/slices/modal";
import { chooseJenjangPendidikan } from "src/redux/slices/jenjang";
import useAuth from "src/hooks/useAuth";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
  },
}));

// ----------------------------------------------------------------------

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default function DashboardLayout({ children }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { modalJenjang } = useSelector((state) => state.modals);
  const { applications = [], user } = useAuth();

  const handleClose = () => {
    dispatch(switchModalJenjang(false));
  };

  const goToPendidikan = (unit) => {
    let newUnit = { ...unit };
    if (unit.unit_name == "KB") {
      newUnit["unit_id"] = 3;
      newUnit["unit_name"] = "TK";
      newUnit["unit_type_id"] = 1;
    }
    dispatch(switchModalJenjang(false));
    dispatch(chooseJenjangPendidikan(newUnit));
    const getSAG = applications.find((val) => val.code == "SAG");
    window.location.href = getSAG.host;
  };

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => {
          setOpen(true);
          console.log("1");
        }}
      />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>
        {children}
        <Dialog
          open={modalJenjang}
          maxWidth={"md"}
          onClose={handleClose}
          fullWidth
          scroll="body"
        >
          <DialogTitle
            disableTypography
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              Pilih Jenjang
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {user.unit && (
              <Stack direction="row" justifyContent="space-around">
                {user.unit.map((val, i) => {
                  return (
                    <Button
                      key={i}
                      size="large"
                      variant="contained"
                      color="primary"
                      sx={{ width: "24%" }}
                      onClick={() => goToPendidikan(val)}
                    >
                      {val.unit_name == "KB" ? "TK" : val.unit_name}
                    </Button>
                  );
                })}
              </Stack>
            )}
          </DialogContent>
        </Dialog>
      </MainStyle>
    </RootStyle>
  );
}
