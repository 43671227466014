import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "id",
    label: "Indonesian",
    icon:
      process.env.REACT_APP_BACKEND_MODE == "PROD" ||
      process.env.REACT_APP_BACKEND_MODE == "UAT"
        ? "/sag/static/icons/ic_flag_id.svg"
        : "/static/icons/ic_flag_id.svg",
  },
  {
    value: "cn",
    label: "Chinese",
    icon:
      process.env.REACT_APP_BACKEND_MODE == "PROD" ||
      process.env.REACT_APP_BACKEND_MODE == "UAT"
        ? "/sag/static/icons/ic_flag_cn.svg"
        : "/static/icons/ic_flag_cn.svg",
  },
  {
    value: "en",
    label: "English",
    icon:
      process.env.REACT_APP_BACKEND_MODE == "PROD" ||
      process.env.REACT_APP_BACKEND_MODE == "UAT"
        ? "/sag/static/icons/ic_flag_en.svg"
        : "/static/icons/ic_flag_en.svg",
  },
];

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem("i18nextLng");
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage);

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
