import { createSlice, current } from "@reduxjs/toolkit";
// utils
import { axiosInstance as axios, endpoint } from "../../utils/axios";
import useAuth from "src/hooks/useAuth";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  jenjangByUnits: [],
  chooseJenjang: {},
  index: 0,
  jenjangByParents: [],
};

const slice = createSlice({
  name: "jenjang",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET JENJANG
    getJenjangSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    //GET JENJANG BY UNIT
    getJenjangByUnitSuccess(state, action) {
      const stateJenjang = current(state);
      let dataJenjang = [];
      dataJenjang = stateJenjang.posts.filter(
        (item) => item.employee_unit_id === action.payload.school_id
      );
      state.isLoading = false;
      state.jenjangByUnits = dataJenjang;
    },

    //
    chooseJenjangPendidikan(state, action) {
      state.chooseJenjang = action.payload;
    },

    // GET Tahun Pelajaran AKTIF
    getAvailJenjangByParentSuccess(state, action) {
      state.isLoading = false;
      state.jenjangByParents = action.payload;
      state.error = false;
    },

    // RESET STATE
    reset() {
      return initialState;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getJenjangSuccess, chooseJenjangPendidikan } = slice.actions;

// ----------------------------------------------------------------------

export function getAllJenjang() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(endpoint.jenjang.root);
      dispatch(slice.actions.getJenjangSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const { getAvailJenjangByParentSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getAvailJenjangByParent(parent_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        parent_id: parent_id,
      };
      const response = await axios.get(`${endpoint.student.option}`, {
        params,
      });

      dispatch(
        slice.actions.getAvailJenjangByParentSuccess(
          response.data.map((item) => item.kelas_sp?.jenjang_id)
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getJenjangByUnits(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getJenjangByUnitSuccess(params));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetAllJenjang() {
  return async (dispatch) => {
    dispatch(slice.actions.reset);
  };
}
