import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// redux
import { getInitialize } from "../../redux/slices/auth";
import { getAvailJenjangByParent } from "../../redux/slices/jenjang";
import useAuth from "src/hooks/useAuth";
// ----------------------------------------------------------------------

JwtProvider.propTypes = {
  children: PropTypes.node,
};

export default function JwtProvider({ children }) {
  const dispatch = useDispatch();

  const parent_id = useSelector((state) => state.auth?.user?.parent?.id);

  useEffect(() => {
    dispatch(getInitialize());
  }, [dispatch]);

  useEffect(() => {
    if (parent_id) {
      dispatch(getAvailJenjangByParent(parent_id));
    }
  }, [dispatch, parent_id]);

  return <>{children}</>;
}
