import Icon from "@material-ui/core/Icon";
// material
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import useLocales from "src/hooks/useLocales";

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&.isActiveRoot": {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: "absolute",
      backgroundColor: theme.palette.primary.main,
    },
  },
  "&.isActiveSub": {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    "& .subIcon:before": {
      transform: "scale(2)",
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

// ----------------------------------------------------------------------

export default function SidebarItem(props) {
  const { expandedMenuId, menu = {}, menuClick, activeMenu = {} } = props;
  const { currentLang } = useLocales();
  let menuName = menu.name;
  if (currentLang.value == "en") {
    menuName = menu.name_en ?? menuName;
  } else if (currentLang.value == "cn") {
    menuName = menu.name_cn ?? menuName;
  }

  const _renderChildName = (id, cn, en) => {
    let childName = id;
    if (currentLang.value == "en") {
      childName = en ?? childName;
    } else if (currentLang.value == "cn") {
      childName = cn ?? childName;
    }

    return childName;
  };

  if (menu.children && menu.children.length > 0) {
    return (
      <>
        <ListItemStyle
          button
          onClick={() => menuClick(menu)}
          key={menu.id}
          selected={expandedMenuId && menu.id === activeMenu.parent_id}
        >
          <ListItemIcon>
            {" "}
            <Icon>{menu.icon}</Icon>{" "}
          </ListItemIcon>
          <ListItemText primary={menuName} />
          {expandedMenuId === menu.id ? <ExpandLess /> : <ExpandMore />}
        </ListItemStyle>
        <Collapse in={expandedMenuId === menu.id} timeout="auto" unmountOnExit>
          <List style={{ paddingLeft: 16 }}>
            {menu.children &&
              menu.children.map((child) => (
                <ListItemStyle
                  button
                  onClick={() => menuClick(child)}
                  key={child.id}
                  selected={activeMenu.id === child.id}
                >
                  <ListItemIcon>
                    {" "}
                    <Icon>{child.icon}</Icon>{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary={_renderChildName(
                      child.name,
                      child.name_cn,
                      child.name_en
                    )}
                  />
                </ListItemStyle>
              ))}
          </List>
        </Collapse>
      </>
    );
  } else {
    return (
      <ListItemStyle
        button
        onClick={() => menuClick(menu)}
        key={menu.id}
        selected={activeMenu.id === menu.id}
      >
        <ListItemIcon>
          {" "}
          <Icon>{menu.icon}</Icon>{" "}
        </ListItemIcon>
        <ListItemText primary={menuName} />
      </ListItemStyle>
    );
  }
}
