import { createSlice } from "@reduxjs/toolkit";
// utils
import { axiosInstance as axios, endpoint } from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  index: 0,
};

const slice = createSlice({
  name: "information",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPostsData(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // RESET STATE
    reset() {
      return initialState;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getPostsData } = slice.actions;

// ----------------------------------------------------------------------

export function getInformationData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(endpoint.information.byUser);
      dispatch(slice.actions.getPostsData(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetInformationData() {
  return async (dispatch) => {
    dispatch(slice.actions.reset);
  };
}
