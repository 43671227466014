import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
// utils
import { axiosInstance as axios, endpoint } from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  active: [],
  index: 0,
};

const slice = createSlice({
  name: "perusahaan",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Perusahaan
    getPerusahaanSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
      state.error = false;
    },

    // GET Perusahaan AKTIF
    savePerusahaanSuccess(state, action) {
      state.isLoading = false;
      state.posts.unshift(action.payload);
      state.error = false;
    },

    // RESET STATE
    reset() {
      return initialState;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getPerusahaanSuccess, savePerusahaanSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getPerusahaan(company_id = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(endpoint.perusahaan.option);
      let companies = [];
      if (company_id) {
        companies = response.data.filter((row) => row.id == company_id);
      } else {
        companies = response.data;
      }

      dispatch(slice.actions.getPerusahaanSuccess(companies));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetPerusahaan() {
  return async (dispatch) => {
    dispatch(slice.actions.reset);
  };
}
