import { createSlice } from "@reduxjs/toolkit";
// utils
import { axiosInstance as axios, endpoint } from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  active: [],
  index: 0,
};

const slice = createSlice({
  name: "tahun_ajaran",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TAHUN AJARAN
    getTahunAjaranSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET TAHUN AJARAN AKTIF
    getTahunAjaranAktifSuccess(state, action) {
      state.isLoading = false;
      state.active = action.payload;
    },

    // RESET STATE
    reset() {
      return initialState;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getTahunAjaranSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getAllTahunAjaran() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(endpoint.tahun_pelajaran.option);
      let tahunAjaranActive = response.data.filter((val) => val.is_active == 1);
      dispatch(slice.actions.getTahunAjaranSuccess(response.data));
      dispatch(slice.actions.getTahunAjaranAktifSuccess(tahunAjaranActive));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTahunAjaranAktif() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        endpoint.tahun_pelajaran.option + "?is_active=1"
      );
      dispatch(slice.actions.getTahunAjaranAktifSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetAllTahunAjaran() {
  return async (dispatch) => {
    dispatch(slice.actions.reset);
  };
}
