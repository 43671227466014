import { useState, useEffect } from "react";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { Card, Typography } from "@material-ui/core";
import { switchModalJenjang } from "src/redux/slices/modal";
import { useDispatch } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(3, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

// ----------------------------------------------------------------------

export default function ApplicationCard(props) {
  const dispatch = useDispatch();
  const { application = {}, setOpenDialog } = props;
  const [appHost, setappHost] = useState("");

  const gotoApp = (app) => {
    if (app.code === "SAG") {
      dispatch(switchModalJenjang(true));
    } else {
      window.location.href = app.host;
    }
  };

  // useEffect(() => {
  //   if (appHost !== "") {

  //   }
  // }, [appHost]);
  return (
    <RootStyle onClick={() => gotoApp(application)}>
      <Typography variant="h5">{application.name}</Typography>
    </RootStyle>
  );
}
